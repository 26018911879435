import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as utlAjax } from 'Utils'
import {
  AUTH,
  REQUEST_CANCEL,
  FETCH_DETAILS,
  FETCH_MESSAGES,
  USER_DETAILS,
  GET_IP,
} from './chat.types'
import {
  authResponse,
  fetchDetailsResponse,
  fetchMessagesResponse,
  userDetailsResponse,
  getIpaddressResponse,
} from './chat.actions'
import environment from 'environments/environment'
// import { Apis } from 'Redux/APIs'
const baseUrl = environment.baseUrl
const chatEpic = {}

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
chatEpic.authApi = (action$) =>
  action$.pipe(
    ofType(AUTH),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/trillium-patient-service/v1/authenticate/login`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => authResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

chatEpic.fetchDetailsApi = (action$) =>
  action$.pipe(
    ofType(FETCH_DETAILS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/trillium-patient-service/v1/authenticate/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => fetchDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

chatEpic.fetchMessagesApi = (action$) =>
  action$.pipe(
    ofType(FETCH_MESSAGES),
    switchMap(() =>
      utlAjax({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("url")}`,
        },
        url: `${baseUrl}/trillium-patient-service/v1/chat/patient/messages/list?start=1&limit=19`,
        method: 'GET',
      }).pipe(
        map(
          (response) => fetchMessagesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

chatEpic.userDetailsApi = (action$) =>
  action$.pipe(
    ofType(USER_DETAILS),
    switchMap(() =>
      utlAjax({
        url: `${baseUrl}/trillium-patient-service/v1/patient/`,
        method: 'GET',
      }).pipe(
        map(
          (response) => userDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

chatEpic.getIpaddressApi = (action$) =>
  action$.pipe(
    ofType(GET_IP),
    switchMap(() =>
      ajax({
        url: `https://api.db-ip.com/v2/free/self`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getIpaddressResponse(response.response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default chatEpic
