import { combineEpics } from 'redux-observable'
import chatEpic from './Chat/chat.epic'

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  chatEpic.authApi,
  chatEpic.fetchDetailsApi,
  chatEpic.fetchMessagesApi,
  chatEpic.userDetailsApi,
  chatEpic.getIpaddressApi,
)
export default rootEpic
